:root {
    --height: 80vh;
    --width: 100%
}

.slider__container {
    height: var(--height);
    width: var(--width);
    position: relative;
    margin: auto;
    overflow: hidden;
}

.active {
    display: inline-block;
    /* height: 100%; */
}

.inactive {
    display: none;
}

.slides {
    height: var(--height);
    width: var(--width);
    position: relative;
}

.slider__image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 10;
    /* border: 2px solid red; */
}

.slide__description {
    width: 100%;
    height: 100%;
    color: white;
    font-size: 18px;
    position: absolute;
    text-align: center;
    top: 85%;
    z-index: 10;
    /* opacity: 0.7; */
    background-color: rgba(0, 0, 0, 0.6);
}

.prev,
.next {
    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 20px;
    font-weight: bold;
    border-radius: 0 5px 5px 0;
}

.prev:hover,
.next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
}

.next {
    right: 0;
    border-radius: 5px 0 0 5px;
}

.all__dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 90%;
    justify-content: center;
    z-index: 200;
}

.dot {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    margin: 0 3px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
}

.dot-active,
.dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

@media (max-width:420px) {
     :root {
        --height: 50vh;
        --width: 100%
    }
    .slider__container {
        height: var(--height);
        width: var(--width);
        position: relative;
        margin: auto;
        overflow: hidden;
    }
    .active {
        display: inline-block;
        /* height: 100%; */
    }
    .inactive {
        display: none;
    }
    .slides {
        height: var(--height);
        width: var(--width);
        position: relative;
    }
    .slider__image {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: contain;
        z-index: 10;
        /* border: 2px solid red; */
    }
    .slide__description {
        width: 100%;
        height: 100%;
        color: white;
        font-size: 14px;
        position: absolute;
        text-align: center;
        top: 82%;
        z-index: 10;
        /* opacity: 0.7; */
        background-color: rgba(0, 0, 0, 0.6);
    }
    .all__dots {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        top: 95%;
        justify-content: center;
        z-index: 200;
    }
    .dot {
        cursor: pointer;
        height: 0.5rem;
        width: 0.5rem;
        margin: 0 3px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        display: inline-block;
    }
    .dot-active,
    .dot:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }
}