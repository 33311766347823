.exam__container {
    margin: 20px 10px;
}

.exam__item {
    margin: 8px 4px;
}

.exam__item>h4 {
    color: #03256C;
    padding: 10px;
}

.exam__item>ul {
    list-style-type: lower-alpha;
    padding: 10px;
}

.exam__item>ul>li {
    line-height: 1.5;
    font-family: Arial, Helvetica, sans-serif;
}