.heading__paragraphy {
    margin: 20px 10px;
}

.heading__paragraphy>p {
    padding: 10px;
    line-height: 2;
    font-family: Arial, Helvetica, sans-serif;
}

.nysc__item {
    margin: 15px 8px;
}

.nysc__cost {
    margin: 5px;
}

.nysc__cost>h4 {
    padding: 5px;
    color: #03256C;
}

.nysc__cost>ol {
    list-style: lower-roman;
    margin: 5px;
}

.nysc__cost>ol>li {
    padding: 5px;
    line-height: 1.5;
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
}