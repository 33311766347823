.contact__title {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    justify-content: center;
    color: #03256C
}

.contact__title>h3 {
    margin-left: 4px;
}

.phone__contact {
    margin: 10px 8px;
}

.contact__list {
    padding: 10px;
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.contact__list>li {
    margin: 5px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 600;
    color: rgb(95, 94, 94);
}

.address__title {
    display: flex;
    align-items: center;
    color: #03256C;
}

.Office__address {
    margin: 15px 5;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.address__container {
    margin: 10px 5px;
    width: 350px;
    background-color: rgb(233, 230, 230);
    padding: 10px;
    border-radius: 10px;
    border-right: 2px double #03256C;
}

.address__container>h4 {
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}

.address__container>p {
    line-height: 2;
    font-family: Arial, Helvetica, sans-serif;
}